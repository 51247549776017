import Container from 'react-bootstrap/Container'

const Delivery = () => {
    return (
        <Container>
            <h1>Доставка</h1>
        </Container>
    )
}

export default Delivery